.hero-section {
    height: 256px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px dashed rgba(var(--cream-rgb), 0.5);
    border-radius: 6px;
    overflow: hidden;

    .hero-background {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        position: absolute;
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .hero-content {
        margin-left: var(--page-large-padding);


        h2 {
            display: flex;
            align-items: center;
        }

        .description {
            font-size: 16px;
            margin: 8px 0 24px;
            line-height: 24px;
        }

        .start-button {
            width: 120px;
        }

        .da-logo-container {
            position: relative;
            height: 46px;
            overflow: hidden;
            margin-left: 12px;
            padding: 0 16px;
            border-radius: 23px;
            box-sizing: content-box;
            width: fit-content;
            background-color: rgba(var(--black-rgb), 0.5);
            box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.3);
            transition: width 0.5s ease-in-out; // Smooth width change

            .da-logo-full {
                height: 100%;
                padding: 10px 0;
                position: absolute;

                &.fade-out {
                    animation: slide-fade-out 0.8s ease-in-out forwards;
                }

                &.slide-in {
                    animation: slide-in 0.8s ease-in-out;
                }

                &.celestia {
                    padding: 6px 0;
                }
            }


        }
    }

    .rollapps-highlights-container {
        height: 512px;
        width: 512px;
        margin-right: -16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-image: radial-gradient(rgba(var(--black-rgb), 0.85) 50%, transparent 70%, transparent);

        .swiper-container .page-dots {
            margin-top: -4px;
        }

        .rollapps-highlights {
            width: 420px;
            margin-bottom: -12px;

            .highlight-card {
                background: none;
                border: navajowhite;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .hero-section {
        height: fit-content;
        align-items: initial;
        padding: var(--page-large-padding) var(--page-padding);
        background-image: linear-gradient(to bottom right, var(--black), rgba(var(--black-rgb), 0.8) 75%, transparent);
    }
}

// Slide Up & Fade Out for Previous Logo
@keyframes slide-fade-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

// Slide In for New Logo
@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
