.da-selector-dialog.da-selector-dialog {
    width: 1024px;
    display: flex;
    flex-direction: column;

    .dialog-title {
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .dialog-content {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 0;

        .dialog-subtitle {
            text-align: center;
            color: var(--text-color-secondary);

            .estimations-label {
                display: block;
                color: var(--cream-dark);
                font-size: 12px;
                margin-top: 4px;
                font-weight: 600;
            }
        }

        .das-grid {
            overflow: auto;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 16px;
            padding: 0 var(--page-padding);
            margin: 20px calc(var(--page-padding) * -1) 24px;

            .da-button {
                --button-height: initial;
                --button-icon-size: 48px;
                --button-color-active: rgba(var(--cream-very-dark-rgb), 0.25);

                flex-direction: column;
                padding: var(--page-small-padding);
                border: 1px solid var(--background-color-secondary);

                .da-logo {
                    margin-bottom: 8px;
                }

                .da-name {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.75;
                }

                .da-properties {
                    width: 100%;
                    margin-top: 12px;
                    padding-top: 6px;
                    border-top: 1px solid var(--border-color);

                    .da-property {
                        display: flex;
                        color: var(--cream);
                        margin-top: 12px;

                        .property-name {
                            flex: 1;
                            color: var(--cream-very-dark);
                            text-align: start;
                        }

                    }
                }

                &.active {
                    border-color: var(--blue);
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .da-selector-dialog.da-selector-dialog .dialog-content .das-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;

        .da-button {
            padding: var(--page-padding);

            .da-properties {
                padding-top: 4px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .da-selector-dialog.da-selector-dialog .dialog-content .das-grid {
        grid-template-columns: repeat(2, 1fr);

        .da-button .da-properties {
            margin-top: 8px;
        }
    }
}


@media only screen and (max-width: 480px) {
    .da-selector-dialog.da-selector-dialog .dialog-content {
        .dialog-subtitle .estimations-label {
            margin-top: 8px;
        }

        .das-grid {
            grid-template-columns: repeat(1, 1fr);
            margin-top: 16px
        }
    }
}



